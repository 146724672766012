import React, { useEffect, useState } from 'react';
import EnCamino from './camino';
import Generar from './generar'; 
import GenerarCambio from './generarcambio';
import LogisticaDash from './logisticadash'; 
//import NuevaTienda from "./nuevatienda";
import Consolidados from './logisticaconsolidados';
import AdministrarChoferes from './logisticachoferes';
import './logistica.css';
import AdministrarTiendas from './logisticasnuevatienda';
import Mensajeria from './mensajeria';
import Soporte from './soporte';

//import Configuracion from "./configuracion";
import Salir from './salir';
//import { useNavigate } from "react-router-dom";//para salir

const Logistica = () => {
  const [selectedItem, setSelectedItem] = useState('logisticadash');
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [id_logis, setId_logis] = useState(' '); // nombre usuario
  const [Name_Logistica] = useState(localStorage.getItem('nombre_logistica'));
  const [userNombre] = useState(localStorage.getItem('userNombre'));
  const [usuarioData, setUsuarioData] = useState(null); // Estado para almacenar los datos de la logística
  const [userId, setUserId] = useState('userId');
  const [isFormVisible, setFormVisible] = useState(false); // Estado para controlar la visibilidad del formulario

  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    console.log('userId:' + userId);
    setId_logis(localStorage.getItem('id_logis'));
    console.log('logistica IDE:' + id_logis);
  }, [userId, id_logis]);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'salir') {
      window.location.href = 'https://www.shipmaster.com.ar';
    } else {
      setSelectedItem(item);
    }
  };

  const handleConfig = async (userId) => {
    setFormVisible(true); // Mostrar el formulario al hacer clic en el botón de configuración
    try {
      const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/configUser.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usuarioId: userId }),
      });

      const data = await response.json();
      setUsuarioData(data); // Guardar los datos en el estado
    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUsuarioData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/configUserUpdate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usuarioData),
      });

      if (response.ok) {
        alert('Usuario actualizado correctamente');
        setFormVisible(false); // Ocultar el formulario después de actualizar los datos
      } else {
        alert('Hubo un error al actualizar los datos');
      }
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
    }
  };

  const handleCancel = () => {
    setFormVisible(false); // Ocultar el formulario al cancelar
  };

  const menuItems = [
    { id: 'logisticadash', label: 'Dashboard', icon: 'fas fa-chart-line', color: '#43099a' },
    { id: 'generar', label: 'Nuevo Envio', icon: 'fas fa-file-invoice', color: '#43099a' },
    { id: 'generarcambio', label: 'Etiqueta Cambio', icon: 'fas fa-redo', color: '#43099a' },
    { id: 'camino', label: 'En Camino', icon: 'fas fa-map-marker-alt', color: '#43099a' },
    //{ id: 'viajando', label: 'Entregados', icon: 'fas fa-database', color: '#6b6b6b' },
    //{ id: 'historico', label: 'Historico de envios', icon: 'fas fa-leaf', color: 'green' },
    //{ id: "nuevatienda", label: "Nueva Tienda",icon:"fas fa-question-circle", color:"#43099a" },
    { id: 'consolidados', label: 'Consolidados', icon: 'fas fa-table', color: '#43099a' },
    { id: 'admtiendas', label: 'Administrar Tiendas', icon: 'fas fa-table', color: '#43099a' },
    { id: 'admchoferes', label: 'Usuarios APP', icon: 'fas fa-user-shield', color: '#43099a' },
    //{ id: 'mensajeria', label: 'Mensajeria', icon: 'fas fa-send', color: 'blue' },
    { id: 'soporte', label: 'Soporte', icon: 'fas fa-headset', color: '#43099a' },
    { id: 'salir', label: 'Salir', icon: 'fas fa-sign-out-alt', color: 'red' },
  ];

  return (
    <div className='dashboard' >
      <div className={`sidebar ${isSidebarVisible ? 'expanded' : ''}`}>
        <div style={{margin: '0 0 0 0'}}>
          <img
            src={`https://shipmaster.com.ar/backoffice/imgLogisticas/${Name_Logistica}.png`}
            alt={`Imagen de ${Name_Logistica}.png`}
            className='img-logistica'
          />
          <h6>Portal Logistica</h6>
          <h6>
            {Name_Logistica} - {id_logis}
          </h6>
          {/* <p style={{ display: 'flex', alignItems: 'center' }}>
         <button onClick={() => handleConfig(userId)} className='button-config'>
              <i className='fas fa-cog'></i>
            </button>   User: {userNombre} 
            
          </p> */}
        </div>
        <ul>
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.id}
              onClick={() => handleItemClick(menuItem.id)}
              className={selectedItem === menuItem.id ? 'active' : ''}
            >
              <i class={menuItem.icon} style={{ color: menuItem.color }}></i> {menuItem.label}
            </li>
          ))}
        </ul>
      </div>
      <div className='contentDash'>
        <div className='contentDash1'>
          <div className='contentDashConfig'>
            {isFormVisible && usuarioData && (
              <form className='logistica-formConfig'>
                <h2>Editar Usuario</h2>
                <label>
                  Nombre :
                  <input
                    type='text'
                    name='nombre_usuario'
                    value={usuarioData.nombre_usuario || ''}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  CUIT :
                  <input
                    type='text'
                    name='cuit_usuario'
                    value={usuarioData.cuit_usuario || ''}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Mail:
                  <input
                    type='email'
                    name='mail_usuario'
                    value={usuarioData.mail_usuario || ''}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Contacto:
                  <input
                    type='text'
                    name='contacto_usuario'
                    value={usuarioData.contacto_usuario || ''}
                    onChange={handleInputChange}
                  />
                </label>
                <button type='button' className='button-updateConfig' onClick={handleUpdate}>
                  Actualizar
                </button>
                <button type='button' className='button-cancelConfig' onClick={handleCancel}>
                  Cancelar
                </button>
              </form>
            )}
          </div>
        </div>
        <div className='toggle-container'>
          <div className={`toggle-button ${isSidebarVisible ? 'expanded' : ''}`} onClick={toggleSidebar}>
            <span className='arrow'></span>
          </div>
        </div>
        {/* Componentes del dashboard */}
        <div className={`content ${isSidebarVisible ? 'expanded' : ''}`} style={{ marginTop: '50px', overflowY: 'true'}}>
          {/* Renderizar el componente correspondiente según la selección del menú */}
          {selectedItem === 'logisticadash' && <LogisticaDash />}
          {selectedItem === 'generar' && <Generar />}
          {selectedItem === 'generarcambio' && <GenerarCambio />}
          {selectedItem === 'camino' && <EnCamino />}
          {/*{selectedItem === "nuevatienda" && <NuevaTienda />}*/}
          {selectedItem === 'consolidados' && <Consolidados />}
          {selectedItem === 'admtiendas' && <AdministrarTiendas />}
          {selectedItem === 'admchoferes' && <AdministrarChoferes />}
          {/* {selectedItem === 'mensajeria' && <Mensajeria />} */}
          {selectedItem === 'soporte' && <Soporte />}
          {selectedItem === 'salir' && <Salir />}
        </div>
      </div>
    </div>
  );
};

export default Logistica;
