import React from "react";
import { Link } from 'react-router-dom';
import Logo from './Image/logo/logoShipMaster.png';
import './navigation.css'; // Importamos un archivo CSS

export const Navigation = () => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header' style={{ display: 'flex', alignItems: 'center' }}>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            style={{ marginTop: '-20px', marginLeft: 'auto', marginRight: '10px' }}
          >
            <span className='sr-only'>XQR</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <Link
            to='/Usuarios'
            className='mobile-usuarios-button' // Añadimos una clase para aplicar estilos
            style={{
              textDecoration: 'none',
              color: '#333',
              fontWeight: 'bold',
              padding: '10px 15px',
              backgroundColor: '#c076dd',
              borderRadius: '5px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, transform 0.3s',
              marginLeft: '10px',
              marginTop: '-50px',
            }}
            onMouseEnter={(e) => {
              e.target.style.color = '#007bff';
              e.target.style.backgroundColor = '#e0e0e0';
              e.target.style.transform = 'scale(1.02)';
            }}
            onMouseLeave={(e) => {
              e.target.style.color = '#333';
              e.target.style.backgroundColor = '#c076dd';
              e.target.style.transform = 'scale(1)';
            }}
          >
            Usuarios
          </Link>
          <p style={{ color: 'rgba(255, 255, 255, 0) 0%', marginTop:'-70px'}}>{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}</p>
        <a className='navbar-brand page-scroll' href='/#page-top' style={{ marginRight: '10px' }}>
          <img
            src={Logo}
            width='80'
            style={{ position: 'relative', top: '-30px', }}
            alt='shipmaster'
          />
        </a>
      </div>

      <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
        <ul className='nav navbar-nav navbar-right' style={{ margin: '-20px 0' }}>
          <li>
            <a href='/#about' className='page-scroll' >Acerca</a>
          </li>
          <li>
            <a href='/#team' className='page-scroll'>Team</a>
          </li>
          <li>
            <a href='/#contact' className='page-scroll'>Contacto</a>
          </li>
          <li style={{ listStyleType: 'none', margin: '0px 0', padding: '-100px', backgroundColor: '#c076dd', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'background-color 0.3s, transform 0.3s', justifyContent: 'flex-end' }}>
            <Link
              to='/Usuarios'
              style={{ textDecoration: 'none', color: '#333', fontWeight: 'bold' }}
              onMouseEnter={(e) => {
                e.target.style.color = '#007bff';
                e.target.parentNode.style.backgroundColor = '#e0e0e0';
                e.target.parentNode.style.transform = 'scale(1.02)';
              }}
              onMouseLeave={(e) => {
                e.target.style.color = '#333';
                e.target.parentNode.style.backgroundColor = '#c076dd';
                e.target.parentNode.style.transform = 'scale(1)';
              }}
            >
              Usuarios
            </Link>
          </li>
        </ul>
      </div>
    </div>
    </nav >
  );
};

export default Navigation;