import React from "react";
import Marce from './Image/team/ceo_director.jpg';
import Aro from './Image/team/ceo_programador.png';

export const Team = (props) => {
  return (
    <div id='team' className='teamback' style={{ paddingBottom: '5px', backgroundColor: '#bdc3c7' }}>
      <div className='section-title'>
        <h2 style={{ textAlign: 'center' }}>Autores</h2>
      </div>
      <div className='container'>
        <div className='row'>
          {props.data &&
            props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-12'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Para centrar horizontalmente
                    marginBottom: '20px',
                    width: '90%', // Ancho del contenedor
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginRight: '10px', // Espacio entre las imágenes
                      width: '45%', // 50% del ancho para cada imagen
                    }}
                  >
                    <div style={{ width: '45%' }}>
                      <img
                        src={Marce}
                        alt={d.name}
                        className='img-responsive'
                        style={{ width: '100%', borderRadius: '8px' }}
                      />
                    </div>
                    <h4 style={{ textAlign: 'center' }}>{d.name}</h4>
                    <p style={{ textAlign: 'center', color: 'black' }}>{d.job}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: '10px', // Espacio entre las imágenes
                      width: '45%', // 50% del ancho para cada imagen
                    }}
                  >
                    <div style={{ width: '65%' }}>
                      <img
                        src={Aro}
                        alt={d.name1}
                        className='img-responsive'
                        style={{ width: '80%', borderRadius: '8px' }}
                      />
                    </div>
                    <h4 style={{ textAlign: 'center' }}>{d.name1}</h4>
                    <p style={{ textAlign: 'center', color: 'black' }}>{d.job1}</p>
                  </div>
                </div>
                <div
                  style={{
                    flex: 2,
                    width: '90%',
                    margin: '20px auto',
                    textAlign: 'justify',
                    color: 'black',
                  }}
                >
                  <p>{props.data && d.text ? <div dangerouslySetInnerHTML={{ __html: d.text }} /> : 'Cargando...'}</p>
                  <p>{props.data && d.text1 ? <div dangerouslySetInnerHTML={{ __html: d.text1 }} /> : 'Cargando...'}</p>
                  <p>{props.data && d.text2 ? <div dangerouslySetInnerHTML={{ __html: d.text2 }} /> : 'Cargando...'}</p>
                  <p>{props.data && d.text3 ? <div dangerouslySetInnerHTML={{ __html: d.text3 }} /> : 'Cargando...'}</p>
                </div>
                <br />
                <br />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
