import QRCode from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import "./generar.css";

const GenerarCambio = () => {
  // Estado del formulario
  const [formData, setFormData] = useState({
    factura: "",
    descripcion: "",
    cantidad: "",
    valorTotal: "",
    direccion: "",
    piso: "",
    departamento: "",
    altura: "",
    comuna: "",
    barrio: "",
    cp: "",
    localidad: "",
    telefono: "",
    nombreContacto: "",
    observaciones: "",
    mailContacto: "",
    domicilioTipo: "",
    horarioEntrega: "",
    origen_id: "",
    origen_nombre: "",
    id_tienda: "",
  });

  // Otros estados
  const [userId, setUserId] = useState("");
  const [userNombre, setUserNombre] = useState("");
  const [nameTienda, setNameTienda] = useState("");
  const [direccionTienda, setDireccionTienda] = useState("");
  const [telefonoTienda, setTelefonoTienda] = useState("");
  const [contacto_tienda, setContactoTienda] = useState("");
  const [idTienda, setidTienda] = useState("");
  const [idLogistica, setidLogistica] = useState("");
  const [nombre_logistica, setnombreLogistica] = useState("");
  const [mail_logistica, setmailLogistica] = useState("");
  const [direccion, setDireccion] = useState("");
  const [altura, setAltura] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestion, setCurrentSuggestion] = useState("");
  const [cp, setPostalCode] = useState("");
  const [comuna, setCommune] = useState("");
  const [barrio, setBarrio] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [nombreContacto, setnombreContacto] = useState("");
  const [domicilioTipo, setdomicilioTipo] = useState("");
  const [generatedData, setGeneratedData] = useState({
    id_generar: "",
    hash: "",
    hashCtrl: "",
    pin: "",
  });
  const [qrData, setQrData] = useState({});
  const logoUrl = "https://shipmaster.com.ar/img/logo/xqr_qr.png"; // Asegúrate de que esta ruta sea válida
  const [qrsData, setQrsData] = useState({});
  const [qrCtrl, setQrCtrl] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Ref para el campo de dirección
  const inputRef = useRef(null);

  useEffect(() => {
    // Inicializar los datos del usuario y tienda desde localStorage
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setNameTienda(localStorage.getItem("name_tienda"));
    setDireccionTienda(localStorage.getItem("direccion_tienda"));
    setTelefonoTienda(localStorage.getItem("telefono_tienda"));
    setidLogistica(localStorage.getItem("id_logis"));
    setnombreLogistica(localStorage.getItem("nombre_logistica"));
    setmailLogistica(localStorage.getItem("mail_logistica"));
    setContactoTienda(localStorage.getItem("contacto_tienda"));
    setidTienda(localStorage.getItem("id_tienda"));
  }, []);

  useEffect(() => {
    if (formData.direccion.length > 1) {
      fetch(
        `https://shipmaster.com.ar/backoffice/php/admindash/codigospostales.php?query=${encodeURIComponent(
          formData.direccion
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
        })
        .catch((err) => setError("Error fetching suggestions: " + err.message));
    } else {
      setSuggestions([]);
    }
  }, [formData.direccion]);

  useEffect(() => {
    if (formData.altura && formData.direccion) {
      console.log(
        "ingresando con calle y altura",
        formData.altura,
        formData.direccion
      );
      fetch(
        `https://shipmaster.com.ar/backoffice/php/admindash/codigospostales.php?street=${encodeURIComponent(
          formData.direccion
        )}&height=${encodeURIComponent(formData.altura)}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Datos recibidos de calle y altura:", data);
          setCommune(data.comuna || ""); // Actualiza el estado de la comuna
          setBarrio(data.barrio || ""); // Actualiza el estado del barrio
          setPostalCode(data.codigo_postal || ""); // Cambia 'cp' a 'codigo_postal'
          setLocalidad(data.localidad || ""); // Actualiza el estado de la localidad
        })
        .catch((err) =>
          setError("Error fetching address details: " + err.message)
        );
    }
  }, [formData.altura, formData.direccion]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && currentSuggestion) {
      setFormData((prevData) => ({
        ...prevData,
        direccion: currentSuggestion,
      }));
      setSuggestions([]);
      setCurrentSuggestion("");
      e.preventDefault(); // Para evitar que el formulario se envíe
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({ ...prevData, direccion: suggestion }));
    setSuggestions([]);
  };

  const fecha = new Date().toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const fechaXS = new Date().toLocaleDateString("es-ES", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const generateRandomPin = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const transformString = (str) => {
    const currentMinutes = new Date().getMinutes();
    return str.split("").reverse().join("") + currentMinutes;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requiredFields = [
      //"factura",
      //"descripcion",
      //"cantidad",
      //"valorTotal",
      "direccion",
      "altura",
      "piso",
      "departamento",
      "comuna",
      "barrio",
      "cp",
      "localidad",
      //"telefono",
      "nombreContacto",
      "mailContacto",
      "observaciones",
      "domicilioTipo",
      "horarioEntrega",
    ];
    const hasEmptyField = requiredFields.some(
      (field) => formData[field].trim() === ""
    );


    if (isNaN(formData.direccion) === false) {
      setErrorMessage("El campo 'direccion' debe contener texto.");
      setIsLoading(false);
      return;
    }
    if (isNaN(formData.cantidad) === true) {
      setErrorMessage("El campo 'altura' debe contener texto.");
      setIsLoading(false);
      return;
    }


    if (isNaN(formData.comuna) === true) {
      setErrorMessage("El campo 'comuna' debe contener texto.");
      setIsLoading(false);
      return;
    }

    // Check for valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.mailContacto)) {
      setErrorMessage("Ingrese una dirección de correo electrónico válida.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const id_generar = Date.now().toString();
      const hash = `XQR${formData.origen_id.padStart(3, "0")}${id_generar}`;
      const hashs = `${formData.origen_id.padStart(3, "0")}${id_generar}`;
      const hashCtrl = `CQR${transformString(
        formData.origen_id.padStart(3, "0") + id_generar
      )}`;
      const pin = generateRandomPin();

      const qrData = {
        hash,
      };

      const qrsData = {
        hashs,
      };

      const qrCtrl = {
        hashCtrl,
      };

      const saveResponse = await fetch(
        "https://shipmaster.com.ar/backoffice/php/admindash/generar.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            id_generar,
            hash,
            hashCtrl,
            localidad,
            barrio,
            comuna,
            cp,
            pin,
            id_chofer: userId,
            id_tienda: idTienda,
            id_logistica: idLogistica,
            estado: 12, /// revisar este estado
          }),
        }
      );

      if (!saveResponse.ok) {
        throw new Error("Error al guardar los datos");
      }

      const result = await saveResponse.json();

      setGeneratedData({
        id_generar,
        hash,
        hashCtrl,
        pin,
      });

      setQrData(hash);
      setQrsData(qrsData);
      setQrCtrl(qrCtrl);
      setModalIsOpen(true);
      await fetch(
        "https://shipmaster.com.ar/backoffice/php/mail/generate_qr.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            qrCtrl: qrCtrl.hashCtrl,
          }),
        }
      );

      const mailResponse = await fetch(
        "https://shipmaster.com.ar/backoffice/php/mail/sendmail.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            para: formData.mailContacto,
            asunto: "Informacion del envio",
            cuerpo: `
            <h3>¡Gracias por tu compra en ${nameTienda}!</h3>
            <p>Tu pedido ha sido recibido y está en proceso de preparación.</p>
            <p><strong>Dirección de Entrega:</strong> ${formData.direccion} piso:${formData.piso} dpto:${formData.departamento}</p>
            <h5> >>>>> IMPORTANTE <<<<< </h5>
            <p>Para garantizar que tu pedido llegue a la persona correcta, el repartidor te solicitará este código QR</p>
            <p><img src="https://shipmaster.com.ar/backoffice/php/mail/qrimage/qr_code.png" alt="Código SQR" style="max-width: 200%; height: auto;" /></p>
            <p>Asegúrate de tenerlo a mano.</p>
            <p>En caso de que otra persona reciba el pedido, este es el PIN que le tiene que dar al repartidor:</p>
            <p><strong>Tu código PIN es:</strong><h3>${pin}</h3></p>
            <p>¡Gracias por confiar en nosotros!</p>
            <p>Atentamente,<br>El equipo de ${nameTienda} & XQR</p>
          `,
          }),
        }
      );

      if (!mailResponse.ok) {
        throw new Error("Error al enviar el correo");
      }

      setSuccessMessage("¡Etiqueta CAMBIO generada y correo enviado con éxito!");
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const printModal = () => {
    const content = document.getElementById("printable-content");
    if (!content) return;

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${generatedData.hash}</title>
          <style>
            @media print {
              body {
                visibility: hidden;
              }
  
              #printable-content {
                visibility: visible;
                position: absolute;
                left: 0;
                top: 0;
                width: 100mm; /* Tamaño para que entren 3 por hoja A4 horizontal */
                height: 80mm;
                page-break-before: auto;
                page-break-after: auto;
                font-size: 12px;
              }
  
              #printable-content img {
                max-width: 50px !important;
                height: auto !important;
                display: block; /* Asegura que no se estire */
              }
  
              .qr-container canvas {
                width: 100px !important;
                height: 100px !important;
              }
              
              .button-container {
                  display: none !important; /* Oculta los botones */
                }
            
            }
          </style>
        </head>
        <body>
          <div id="printable-content">
            ${content.innerHTML}
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };



  return (
    <div className="containerGenerar">
      <div className="tituloGenerar">
        <h3>Generar Etiqueta XQR de CAMBIO</h3>
      </div>
      <div style={{ fontSize: 18, color: "black", backgroundColor: "green" }}>
        {isLoading && <p>Loading...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>
      <div style={{ height: 'auto' }}><form onSubmit={handleSubmit}>
        <div className="form-containerGenerar">
          <div className="form-groupGenerar">
            <div className="form-row">
              <div className="form-column">
                <label>Factura:</label>
                <input
                  type="text"
                  name="factura"
                  className="input-gris-oscuro"
                  value={formData.factura}
                  onChange={handleChange}
                  placeholder="Factura"
                />
              </div>
              <div className="form-column">
                <label>Descripcion:</label>
                <input
                  type="text"
                  name="descripcion"
                  className="input-gris-oscuro"
                  value={formData.descripcion}
                  onChange={handleChange}
                  placeholder="Descripcion"
                />
              </div>
              <div className="form-column">
                <label>Cantidad:</label>
                <input
                  type="text"
                  name="cantidad"
                  className="input-gris-oscuro"
                  value={formData.cantidad}
                  onChange={handleChange}
                  placeholder="Cantidad"
                />
              </div>
              <div className="form-column">
                <label>Total:</label>
                <input
                  type="text"
                  name="valorTotal"
                  className="input-gris-oscuro"
                  value={formData.valorTotal}
                  onChange={handleChange}
                  placeholder="$0000"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <label>Dirección:</label>
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    name="direccion"
                    value={formData.direccion}
                    className="input-gris-oscuro"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Dirección"
                    ref={inputRef}
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className={`suggestion-item ${formData.direccion === suggestion.nombre_calle ? 'selected' : ''}`}
                          onClick={() => handleSuggestionClick(suggestion.nombre_calle)}
                        >
                          {suggestion.nombre_calle}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="form-column">
                <label>Altura:</label>
                <input
                  type="text"
                  name="altura"
                  value={formData.altura}
                  onChange={handleChange}
                  placeholder="altura"
                  className="input-gris-oscuro"
                />
              </div>
              <div className="form-column">
                <label>Piso:</label>
                <input
                  className="input-gris-oscuro"
                  type="text"
                  name="piso"
                  placeholder="piso"
                  value={formData.piso}
                  onChange={handleChange}
                />
              </div>
              <div className="form-column">
                <label>Departamento:</label>
                <input
                  className="input-gris-oscuro"
                  type="text"
                  name="departamento"
                  placeholder="Dpto."
                  value={formData.departamento}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <label>Localidad:</label>
                <input
                  type="text"
                  name="localidad"
                  className="input-gris-oscuro"
                  value={localidad}
                  placeholder="localidad"
                  onChange={(e) => setLocalidad(e.target.value)}
                />
              </div>
              <div className="form-column">
                <label>Barrio:</label>
                <input
                  type="text"
                  name="barrio"
                  placeholder="barrio"
                  className="input-gris-oscuro"
                  value={barrio}
                  onChange={(e) => setBarrio(e.target.value)}
                />
              </div>
              <div className="form-column">
                <label>Comuna:</label>
                <input
                  type="text"
                  name="comuna"
                  placeholder="comuna"
                  className="input-gris-oscuro"
                  value={comuna}
                  onChange={(e) => setCommune(e.target.value)}
                />
              </div>
              <div className="form-column">
                <label>Código Postal:</label>
                <input
                  type="text"
                  name="cp"
                  placeholder="CP"
                  className="input-gris-oscuro"
                  value={cp}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <label>Contacto:</label>
                <input
                  type="text"
                  name="nombreContacto"
                  className="input-gris-oscuro"
                  placeholder="nombre contacto"
                  value={formData.nombreContacto}
                  onChange={handleChange}
                />
              </div>
              <div className="form-column">
                <label>Telefono:</label>
                <input
                  type="text"
                  name="telefono"
                  className="input-gris-oscuro"
                  value={formData.telefono}
                  placeholder="telefono"
                  onChange={handleChange}
                />
              </div>
              <div className="form-column">
                <label>Email:</label>
                <input
                  type="email"
                  placeholder="mail@mail.com"
                  name="mailContacto"
                  className="input-gris-oscuro"
                  value={formData.mailContacto}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column-full">
                <label>Observaciones:</label>
                <input
                  type="text"
                  name="observaciones"
                  placeholder="observaciones"
                  className="input-gris-oscuro"
                  value={formData.observaciones}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <label>Domicilio Tipo:</label>
                <select
                  name="domicilioTipo"
                  className="input-gris-oscuro"
                  value={formData.domicilioTipo}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  <option value="Laboral">Laboral</option>
                  <option value="Particular">Particular</option>
                </select>
              </div>
              <div className="form-column">
                <label>Horario de Entrega:</label>
                <select
                  name="horarioEntrega"
                  className="input-gris-oscuro"
                  value={formData.horarioEntrega}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  <option value="8-13">8 a 13 hs</option>
                  <option value="13-21">13 a 21 hs</option>
                </select>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn-submitGenerar"
            disabled={isLoading}
            style={{ color: 'black' }}
          >
            {isLoading ? 'Generando...' : 'Generar Etiqueta Cambio'}
          </button>
        </div>
      </form></div>
      <p className="labelito">
        Usuario: {userId} {userNombre} - Tienda: {nameTienda}
        {idTienda} - Logistica: {idLogistica} {nombre_logistica}
      </p>
      {modalIsOpen && (


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Etiqueta Generada"
          className="print-modal"
          style={{
            content: {
              width: "500px",
              maxHeight: "80vh",
              margin: "auto",
              border: "4px solid black",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              overflowY: "auto",
              scrollbarWidth: "thin",
            },
          }}
        >
          <div id="printable-content">
            <div className="etiqueta">
              <div className="contenido">
                <div style={{ border: '1px solid black', padding: '10px', color: 'black' }}>
                  <table>
                    <tr>
                      <td style={{ width: '15%', justifyContent: 'center', alignItems: 'center', verticalAlign: 'top' }}>
                        <img
                          src="https://shipmaster.com.ar/img/logo/logoXQR.png"
                          alt="QR"
                          className="logoXS"
                        />

                      </td>
                      <td style={{ whith: '25%' }}>   <QRCode value={qrData} size={32} renderAs="svg" level="H" /></td>
                      <td style={{ width: '60%', textAlign: 'center', color: 'black' }}>
                        <p>
                          <strong>TRACK:{'    '} </strong>
                          {generatedData.hash.slice(0, -4)}
                          <span className="tracking-bold">{generatedData.hash.slice(-4)}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={'2'} style={{ whiteSpace: "nowrap", color: 'black' }}><strong>Cambio:</strong>{formData.factura}</td>
                      <td style={{ textAlign: 'right', marginBottom: '20px', color: 'black' }}>{'    '} {fechaXS}</td>
                    </tr>
                    <tr>
                      <td colSpan={'3'} className="dashed-line"><strong> ✂️_____ Talon cambio para la tienda _______✂️</strong></td>
                    </tr>
                  </table>
                </div>
                <div style={{ border: '1px solid black', padding: '10px', color: 'black' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ whith: '30%' }}></td>
                        <td style={{ whith: '39%', textAlign: 'center' }}>
                          <img
                            src="https://shipmaster.com.ar/img/logo/logoXQR.png"
                            alt="QR"
                            className="logo"
                          />
                        </td>
                        <td style={{ whith: '30%' }}></td>
                        {/* <td colSpan={'2'} style={{ textAlign: 'center' }}>
                          <h3 className="logo-container" style={{ lineHeight: '1', margin: '0 auto' }}>
                            <img
                              src="https://shipmaster.com.ar/img/logo/logoXQR.png"
                              alt="QR"
                              className="logo"
                            />
                          </h3>
                          <hr style={{ width: '20%', margin: '10px auto' }} />
                        </td> */}
                      </tr>
                      <tr>
                        <td className="qr-container" style={{ verticalAlign: 'top' }}>
                          <QRCode value={qrData} size={128} renderAs="svg" level="H" />
                        </td>
                        <td colspan={2} className="origen" style={{ width: '60%', verticalAlign: 'top', paddingLeft: '10px' }}>
                          <h4 style={{ textAlign: 'center' }}>
                            <strong>ORIGEN CAMBIO</strong>
                          </h4>
                          <p>
                            {nameTienda.toUpperCase()} - {idTienda}
                          </p>
                          <p>Dirección: {direccionTienda.toUpperCase()}</p>
                          <p>Tel: {telefonoTienda}</p>
                          <p>Venta: {formData.factura}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr style={{ width: '80%' }} />
                  <h4 className="destino" style={{ textAlign: 'center' }}><strong>DESTINO CAMBIO</strong></h4>
                  <p>
                    {formData.direccion.toUpperCase()} {formData.altura} - Piso: {formData.piso} - Dpto: {formData.departamento}
                  </p>
                  <p>{localidad.toUpperCase()} - {barrio.toUpperCase()} - Comuna: {comuna} - CP: {cp}  </p>
                  <p>Domicilio: {formData.domicilioTipo.toUpperCase()} - Horario Entrega: {formData.horarioEntrega}</p>
                  <p>Nombre: {formData.nombreContacto.toUpperCase()} - Tel: {formData.telefono}</p>
                  <p>Observaciones: {formData.observaciones.toUpperCase()}</p>
                  <hr style={{ width: '80%' }} />
                  <table style={{ color: 'black' }}>
                    <tr>
                      <td className="destino">
                        <h4 style={{ textAlign: 'center' }}>TRACK CAMBIO</h4>
                        <p>
                          {generatedData.hash.slice(0, -4)}
                          <span className="tracking-bold">{generatedData.hash.slice(-4)}</span>
                        </p>
                        <p>{fecha}</p>
                      </td>
                      <td className="qr-container">
                        <QRCode
                          value={qrData}
                          size={128}
                          renderAs="svg"
                          level="H"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="button-container">
                <button onClick={closeModal} className="cerrar-button">Cerrar</button>
                <button onClick={printModal} className="print-button">Imprimir</button>
              </div>
            </div>
          </div>
        </Modal>
      )
      }
    </div >
  );
};

export default GenerarCambio;
