import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

const Identidad = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const [usuario, setUsuario] = useState('');
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [formType, setFormType] = useState(token ? 'completeData' : 'login');

    // Campos faltantes
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [contacto, setContacto] = useState('');
    const [vehiculo, setVehiculo] = useState('');
    const [patente, setPatente] = useState('');
    const [polizaseguro, setPolizaseguro] = useState('');
    //const [usuario, setUsuario] = useState('');
    const [pass, setPass] = useState('');
    const [imagen, setImagen] = useState('');
    const [jerarquia, setJerarquia] = useState('');
    const [rol, setRol] = useState('');

    useEffect(() => {
        if (token) {
            fetch('https://shipmaster.com.ar/backoffice/php/web/obtener_datos_chofer.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setNombre(data.chofer.nombre || '');
                        setDescripcion(data.chofer.descripcion || '');
                        setDireccion(data.chofer.direccion || '');
                        setTelefono(data.chofer.telefono || '');
                        setContacto(data.chofer.contacto || '');
                        setVehiculo(data.chofer.vehiculo || '');
                        setPatente(data.chofer.patente || '');
                        setPolizaseguro(data.chofer.polizaseguro || '');
                        setUsuario(data.chofer.usuario || '');
                        setPass(data.chofer.pass || '');
                        setImagen(data.chofer.imagen || '');
                        setJerarquia(data.chofer.jerarquia || '');
                        setRol(data.chofer.rol || '');
                    } else {
                        setMensaje(data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    setMensaje('Ocurrió un error al obtener los datos.');
                });
        }
    }, [token]);


    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://shipmaster.com.ar/backoffice/php/web/validar_login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ usuario, mail }),
            });
            const data = await response.json();
            if (data.success) {
                setFormType('resetPassword');
            } else {
                setMensaje(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('Ocurrió un error inesperado.');
        }
    };

    const handleResetPasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://shipmaster.com.ar/backoffice/php/web/actualizar_password.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ usuario, password }),
            });
            const data = await response.json();
            if (data.success) {
                setMensaje('Contraseña actualizada con éxito.');
            } else {
                setMensaje(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('Ocurrió un error inesperado.');
        }
    };

    const handleCompleteDataSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://shipmaster.com.ar/backoffice/php/web/completar_datos.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    nombre,
                    descripcion,
                    direccion,
                    telefono,
                    contacto,
                    vehiculo,
                    patente,
                    polizaseguro,
                    usuario,
                    pass,
                    imagen,
                    jerarquia,
                    rol,
                }),
            });
            const data = await response.json();
            if (data.success) {
                setMensaje('Datos completados con éxito.');
            } else {
                setMensaje(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('Ocurrió un error inesperado.');
        }
    };

    return (
        <div style={{ marginTop: '70px' }}>
            {/* ... (código existente para login y resetPassword) */}
            {formType === 'login' && (
                <div style={{ marginTop: '170px' }}>
                    <form onSubmit={handleLoginSubmit}>
                        <input type="text" placeholder="Usuario" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                        <input type="email" placeholder="Mail" value={mail} onChange={(e) => setMail(e.target.value)} />
                        <button type="submit">Aceptar</button>
                        {mensaje && <p>{mensaje}</p>}
                    </form></div>
            )}

            {formType === 'resetPassword' && (
                <div style={{ marginTop: '170px' }}>
                    <form onSubmit={handleResetPasswordSubmit}>
                        <input type="password" placeholder="Nueva Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button type="submit">Actualizar Contraseña</button>
                        {mensaje && <p>{mensaje}</p>}
                    </form>
                </div>
            )}
            {formType === 'completeData' && (
                <div style={{ marginTop: '170px' }}>
                    <h4>Completar Datos de usuario</h4>
                    <form onSubmit={handleCompleteDataSubmit}>
                        <input type="text" placeholder="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        <textarea placeholder="Descripción" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
                        <input type="text" placeholder="Dirección" value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                        <input type="text" placeholder="Teléfono" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                        <input type="text" placeholder="Contacto" value={contacto} onChange={(e) => setContacto(e.target.value)} />
                        <input type="text" placeholder="Vehículo" value={vehiculo} onChange={(e) => setVehiculo(e.target.value)} />
                        <input type="text" placeholder="Patente" value={patente} onChange={(e) => setPatente(e.target.value)} />
                        <input type="text" placeholder="Póliza de Seguro" value={polizaseguro} onChange={(e) => setPolizaseguro(e.target.value)} />
                        <input type="text" placeholder="Usuario" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                        <input type="text" placeholder="Password" value={pass} onChange={(e) => setPass(e.target.value)} />
                        <input type="text" placeholder="Imagen" value={imagen} onChange={(e) => setImagen(e.target.value)} />
                        <input type="text" placeholder="Jerarquía" value={jerarquia} onChange={(e) => setJerarquia(e.target.value)} />
                        <input type="text" placeholder="Rol" value={rol} onChange={(e) => setRol(e.target.value)} />
                        <button type="submit">Completar Datos</button>
                        {mensaje && <p>{mensaje}</p>}
                    </form>
                </div>
            )}
        </div>
    );
};

export default Identidad;