import React, { useState, useEffect } from "react";

const AdminMVP = () => { 
    const [data, setData] = useState({});
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => { 
            try {
                const response = await fetch(`https://www.shipmaster.com.ar/backoffice/php/web/mvp.php?search=${searchTerm}`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Llamar a fetchData
    }, [searchTerm]); // searchTerm como dependencia

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const renderTable = (tableName, tableData) => {
        if (!tableData || tableData.length === 0) {
            return <p>No hay datos para mostrar en {tableName}.</p>; // Mensaje más específico
        }

        const columns = Object.keys(tableData[0]);

        return (
            <div style={{ height: "200px", overflowY: "scroll", border: "1px solid #ccc", marginBottom: "10px" }}>
                <table className="table"> {/* Asegúrate de tener Bootstrap o estilos para la clase "table" */}
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                {columns.map((column) => (
                                    <td key={column}>{row[column]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="containercursos">
            <h4>
                Administrar MVP de SEGUIMIENTO{" "}
                <i className="fas fa-graduation-cap" style={{ paddingLeft: "20px", color: "#0c5da1" }}></i>
            </h4>

            <input type="text" placeholder="Buscar..." value={searchTerm} onChange={handleSearchChange} />

            {Object.keys(data).map((tableName) => (
                <div key={tableName}>
                    <h3>{tableName}</h3>
                    {renderTable(tableName, data[tableName])}
                </div>
            ))}

            <div className="footer">
                <p>shipmaster - Tecnología en shipmaster</p>
            </div>
        </div>
    );
};

export default AdminMVP; // Exportar el componente con el nombre correcto