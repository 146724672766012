import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./tiendadashboard.css";
import icon_QR from "../Image/logo/qr-code_icon.png";
import icon_lupa from "../Image/icons/icon_lupa.png";
//import { Modal } from "bootstrap";
import QRCodeReact from "qrcode.react";
import QRCode from 'qrcode'; //para imrimir el pdf
import Modal from "react-modal";

import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { Line, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const TiendaDashBoard = () => {
  const [data, setData] = useState({
    logisticaTotal: 0,
    ventasRealizadas: 0,
    enviosColectados: 0,
    pedidosEnLogistica: 0,
    distribucionLogistica: 0,
    pedidosEnRuta: 0,
    pedidosEntregados: 0,
    pedidosReprogramados: 0,
    pedidosErroneos: 0,
    pedidosDevueltos: 0,
    pedidosAnulados: 0,
    cambios: 0,
  });

  const [id_logistica, setIdLogistica] = useState(""); // nombre logistica
  const [userId, setUserId] = useState(""); // id de usuario
  const [userNombre, setUserNombre] = useState(""); // nombre usuario
  const [id_tienda, setIdTienda] = useState(""); // id de tienda
  const [isLoading, setIsLoading] = useState(true); // estado para icono de carga
  const [qrInput, setQrInput] = useState(""); // estado para el input del QR
  const [resultData, setResultData] = useState(null); // estado para almacenar los resultados de la búsqueda
  const [error, setError] = useState(""); // estado para manejar errores
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [details, setDetails] = useState([]);
  const [details, setDetails] = useState({ message: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [graficaAgrandada, setGraficaAgrandada] = useState(null);


  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setIdLogistica(localStorage.getItem("id_logis"));
    setIdTienda(localStorage.getItem("id_tienda"));
    console.log('tienda', id_tienda, 'logistica', id_logistica);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      console.log('va a buscar datos de la tienda', id_tienda);
      try {
        const response = await fetch(
          "https://shipmaster.com.ar/backoffice/php/web/dashboardtienda.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({ id_tienda }),
          }
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id_tienda) {
      fetchData();
    }
  }, [id_tienda]);

  const handleCardClick = (metricName) => {
    setSelectedMetric(metricName);
    setIsModalOpen(true);
    setModalIsOpen(true); // Abre el modal
    fetchDetails(metricName, id_tienda); // Llamamos a la API con el id_tienda
  };

  const fetchDetails = async (metricName, id_tienda) => {
    console.log('va a buscar detalles', metricName, ' de la tienda', id_tienda);
    try {
      const response = await fetch(`https://shipmaster.com.ar/backoffice/php/web/TiendaDashMetricas.php?id_tienda=${id_tienda}&metric=${encodeURIComponent(metricName)}`);
      //const response = await fetch(`https://shipmaster.com.ar/backoffice/php/admindash/tiendaVentas.php?id_tienda=${id_tienda}`);
      const data = await response.json();
      //setDetails(data);
      setDetails(data || [{ message: 'No se encontraron Registros' }]);
    } catch (error) {
      setDetails({ message: 'No se encontraron Registros' });
      console.error("Error al obtener los detalles:", error);
    }
  };

  const handleQrInputChange = (e) => {
    setQrInput(e.target.value);
  };

  const handleSearch = async () => {
    setResultData(null); // Limpiar resultados anteriores
    setError(""); // Limpiar errores

    try {
      const response = await fetch(
        "https://shipmaster.com.ar/backoffice/php/web/buscarQR.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ hashCode: qrInput, id_tienda: id_tienda }),
        }
      );

      console.log("Response:", response); // Verificar la respuesta

      if (!response.ok) {
        const errorMessage = await response.text(); // Obtener el mensaje de error
        throw new Error(`Error en la búsqueda: ${errorMessage}`);
      }

      const result = await response.json();
      if (result) {
        //setResultData(result);
        //console.log('result:', result)
        setResultData(result.data); // Accede a la propiedad 'data'
        console.log('result:', result.data); // <-- Y esta también, para ver solo el array
      } else {
        setError("No se encontraron datos para el QR ingresado");
      }
    } catch (error) {
      console.error("Error buscando QR:", error);
      setError(
        "Ocurrió un error al buscar el QR. Verifica la consola para más detalles."
      );
    }
  };

  const {
    logisticaTotal,
    ventasRealizadas,
    enviosColectados,
    pedidosEnLogistica,
    distribucionLogistica,
    pedidosEnRuta,
    pedidosEntregados,
    pedidosReprogramados,
    pedidosErroneos,
    pedidosDevueltos,
    pedidosAnulados,
    cambios,
  } = data;

  const exportToPDF = async () => {
    const doc = new jsPDF();
    let y = 20;
    const margin = 20;
    const lineHeight = 7;
    const qrSize = 50;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight() - margin;

    doc.setFontSize(16);
    const titleWidth = doc.getTextWidth(`Detalles de ${selectedMetric}`);
    doc.text(`Detalles de ${selectedMetric}`, (pageWidth - titleWidth) / 2, y);
    //y += lineHeight * 2;
    y += lineHeight;

    if (details.message) {
      doc.setFontSize(12);
      doc.text(details.message, margin, y);
    } else if (Array.isArray(details) && details.length > 0) {
      for (const item of details) { // Usamos 'for...of' para manejar async/await dentro del bucle
        if (y > pageHeight - qrSize - lineHeight * 7) { // Ajuste para el QR y los datos
          doc.addPage();
          y = margin;
          // doc.setFontSize(16);
          // doc.text(`Detalles de ${selectedMetric} (Continuación)`, (pageWidth - doc.getTextWidth(`Detalles de ${selectedMetric} (Continuación)`)) / 2, y);
          // y += lineHeight * 2;
        }

        doc.setFontSize(16);
        doc.text(` ${item.hashCode} `, margin, y);
        y += lineHeight;
        doc.setFontSize(12);

        doc.text(`Dirección: ${item.direccion} ${item.altura} Piso:${item.piso} Dpto:${item.departamento}`, margin, y);
        y += lineHeight;
        doc.text(`Comuna: ${item.comuna} Barrio: ${item.barrio} CP: ${item.cp}`, margin, y);
        y += lineHeight;
        doc.text(`Contacto: ${item.nombreContacto} Tel: ${item.telefono}`, margin, y);
        y += lineHeight;
        doc.text(`Email: ${item.mailContacto}`, margin, y);
        y += lineHeight;
        doc.text(`Observaciones: ${item.observaciones}`, margin, y);
        y += lineHeight;
        doc.text(`Fecha Venta: ${item.fechayhora} Estado: ${item.estado_descripcion}`, margin, y);
        y += lineHeight;
        doc.text(`Chofer: ${item.nombrechofer}`, margin, y);
        y += lineHeight;
        doc.text(`ID Local: ${item.id_tienda} ID Logística: ${item.id_logistica}`, margin, y);
        //y += lineHeight * 2;
        y += lineHeight;

        try {
          const qrCodeDataURL = await QRCode.toDataURL(item.hashCode);
          doc.addImage(qrCodeDataURL, 'PNG', pageWidth - margin - qrSize, y - lineHeight * 7, qrSize, qrSize);
        } catch (error) {
          console.error('Error al generar el código QR:', error);
          doc.text('Error al generar el código QR', margin, y);
          y += lineHeight;
        }

        doc.line(margin, y, pageWidth - margin, y); // Línea divisoria
        y += lineHeight; // Espacio después de la línea
      }
    } else {
      doc.setFontSize(12);
      doc.text('No se encontraron detalles disponibles.', margin, y);
    }

    doc.save(`${selectedMetric}_XQR.pdf`);
  };

  const exportToXLS = () => {
    if (!Array.isArray(details) || details.length === 0) {
      return;
    }

    const worksheetData = details.map((detail) => ({
      HashCode: detail.hashCode,
      Dirección: `${detail.direccion} ${detail.altura} ${detail.piso} ${detail.departamento}`,
      Teléfono: detail.telefono,
      Email: detail.mailContacto,
      Observaciones: detail.observaciones,
      Chofer: detail.nombrechofer,
      Horario: detail.horarioEntrega,
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Detalles');
    XLSX.writeFile(workbook, `${selectedMetric}_details.xlsx`);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalIsOpen(false); // Cierra el modal
  };


  const chartData = {
    labels: [
      'Logistica Total',
      'Ventas Realizadas',
      'Envíos Colectados',
      'Pedidos en Deposito',
      'Pedidos en Distribucion',
      'Pedidos en Ruta',
      'Pedidos Entregados',
      'Pedidos Reprogramados',
      'Pedidos Erroneos',
      'Pedidos Devueltos',
      'Pedidos Anulados',
      'Cambios',
    ],
    datasets: [
      {
        label: 'Datos del Dashboard',
        data: [
          data.logisticaTotal,
          data.ventasRealizadas,
          data.enviosColectados,
          data.pedidosEnLogistica,
          data.distribucionLogistica,
          data.pedidosEnRuta,
          data.pedidosEntregados,
          data.pedidosReprogramados,
          data.pedidosErroneos,
          data.pedidosDevueltos,
          data.pedidosAnulados,
          data.cambios,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(4, 216, 74, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(25, 247, 173, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(128, 0, 128, 0.6)',
          'rgba(246, 250, 8, 0.6)',
          'rgba(4, 255, 242, 0.6)',
          'rgba(0, 128, 0, 0.6)',
        ],
        borderWidth: 1,
        fill: false,
        borderColor: 'rgb(0, 0, 0)',
        tension: 0.1,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5, // Ajusta el intervalo entre los ticks del eje Y si es necesario
        },
      },
      x: { // Agrega esta sección para configurar el eje X
        ticks: {
          color: 'black', // Establece el color de las etiquetas del eje X a negro
          stepSize: 3,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Oculta la leyenda si no la necesitas
      },
      title: {
        display: true,
        //text: 'Gráfico de Línea', // Título del gráfico
        font: {
          size: 14,
        },
      },
    },
    elements: {
      line: {
        tension: 0.8, // Suaviza las líneas
      },
      point: {
        radius: 4, // Tamaño de los puntos
        hoverRadius: 7, // Tamaño de los puntos al pasar el ratón
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'left', // Muestra la leyenda a la derecha del gráfico
        labels: {
          boxWidth: 5, // Ajusta el ancho de los cuadros de color en la leyenda
          fontSize: 11, // Ajusta el tamaño de la fuente en la leyenda
          color: 'black',
          maintainAspectRatio: true,
        },
      },
      title: {
        display: false,
        //text: 'Gráfico de Torta', // Título del gráfico
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.parsed;
            return `${label}: ${value}`; // Personaliza la etiqueta del tooltip
          },
        },
      },
    },
    cutoutPercentage: 0, // Ajusta el tamaño del agujero en el centro (0 para un gráfico de torta completo)
  };

  const exportCardPDF = async (item) => {
    const doc = new jsPDF();
    let y = 20;
    const margin = 20;
    const lineHeight = 10;
    const qrSize = 50;
    const pageWidth = doc.internal.pageSize.getWidth();

    // Título (hashCode) centrado
    doc.setFontSize(16);
    const titleWidth = doc.getTextWidth(` ${item.hashCode}`);
    doc.text(` ${item.hashCode}`, (pageWidth - titleWidth) / 2, y);
    //y += lineHeight * 2;
    y += lineHeight;

    // Datos a la izquierda
    doc.setFontSize(12);
    doc.text(`Dirección: ${item.direccion} ${item.altura} Piso:${item.piso} Dpto:${item.departamento}`, margin, y);
    y += lineHeight;
    doc.text(`Comuna: ${item.comuna} Barrio: ${item.barrio} CP: ${item.cp}`, margin, y);
    y += lineHeight;
    doc.text(`Contacto: ${item.nombreContacto} Tel: ${item.telefono}`, margin, y);
    y += lineHeight;
    doc.text(`Email: ${item.mailContacto}`, margin, y);
    y += lineHeight;
    doc.text(`Observaciones: ${item.observaciones}`, margin, y);
    y += lineHeight;
    doc.text(`Fecha Venta: ${item.fechayhora} Estado: ${item.estado_descripcion}`, margin, y);
    y += lineHeight;
    doc.text(`Chofer: ${item.nombrechofer}`, margin, y);
    y += lineHeight;
    doc.text(`ID Local: ${item.id_tienda} ID Logística: ${item.id_logistica}`, margin, y);
    y += lineHeight * 2;

    // Código QR a la derecha
    try {
      const qrCodeDataURL = await QRCode.toDataURL(item.hashCode);
      doc.addImage(qrCodeDataURL, 'PNG', pageWidth - margin - qrSize, y - lineHeight * 7, qrSize, qrSize);
    } catch (error) {
      console.error('Error al generar el código QR:', error);
      doc.text('Error al generar el código QR', margin, y);
      y += lineHeight;
    }

    // Línea de división horizontal
    doc.line(margin, y, pageWidth - margin, y); // Dibuja la línea
    doc.save(`${item.hashCode}_detalle.pdf`);
  };

  return (
    <div style={{ marginTop: '45px' }}>
      <div className="centro">
        <h4 className="centro">
          TIENDA Dash Board {id_tienda}
          <i
            className="fas fa-chart-bar icono"
            style={{ color: "#b87f05" }}
          ></i>
        </h4>
      </div>

      <div className="dashboard-container">
        <div>
          {isLoading ? (
            <div className="loading-icon">
              <i
                className="fas fa-spinner fa-spin"
                style={{ fontSize: "50px", color: "#b87f05" }}
              ></i>
              <p>Cargando datos...</p>
            </div>
          ) : (
            <div>
              <div className="dashboard-grid">
                {[
                  { label: "Total Envios", value: logisticaTotal, key: "logisticaTotal" },
                  { label: "Ventas", value: ventasRealizadas, key: "ventasRealizadas" },
                  { label: "Colectados", value: enviosColectados, key: "enviosColectados" },
                  { label: "Deposito", value: pedidosEnLogistica, key: "pedidosEnLogistica" },
                  { label: "Distribucion", value: distribucionLogistica, key: "distribucionLogistica" },
                  { label: "Ruta", value: pedidosEnRuta, key: "pedidosEnRuta" },
                  { label: "Reprogramados", value: pedidosReprogramados, key: "pedidosReprogramados" },
                  { label: "Erroneos", value: pedidosErroneos, key: "pedidosErroneos" },
                  { label: "Devueltos", value: pedidosDevueltos, key: "pedidosDevueltos" },
                  { label: "Anulados", value: pedidosAnulados, key: "pedidosAnulados" },
                  { label: "Cambios", value: cambios, key: "cambios" },
                  { label: "Entregados", value: pedidosEntregados, key: "pedidosEntregados" },
                ].map((item) => (
                  <div key={item.key} className="dashboard-card" onClick={() => handleCardClick(item.key)}>
                    <h4 className="titulocard" style={{ adjustsFontSizeToFit: true }} numberOfLines={1}>{item.label}</h4>
                    <p>{item.value}</p>
                  </div>
                ))}
              </div>
              <div className="chart-container">
                <div className={`chart-item ${graficaAgrandada === 'linea' ? 'agrandada' : ''}`}>
                  <Line data={chartData} options={lineChartOptions} />
                  {/* <button onClick={() => setGraficaAgrandada('linea')}>
                    {graficaAgrandada === 'linea' ? 'Reducir' : 'Ampliar'} Gráfica de Línea
                  </button> */}
                </div>
                <div className={`chart-item ${graficaAgrandada === 'pastel' ? 'agrandada' : ''}`}>
                  <Pie data={chartData} options={pieChartOptions} />
                  {/* <button onClick={() => setGraficaAgrandada('pastel')}>
                    {graficaAgrandada === 'pastel' ? 'Reducir' : 'Ampliar'} Gráfica de Pastel
                  </button> */}
                </div>
              </div>
            </div>
          )}



        </div>
        {isModalOpen && selectedMetric && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Detalles"
            className="print-modal"
            style={{
              content: {
                width: "90%",
                maxHeight: "90%",
                margin: "auto",
                marginTop: "50px",
                border: "4px solid black",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                overflowY: "auto",
                scrollbarWidth: "thin",
              },
            }}
          >
            <div>
              <h4>Detalles de {selectedMetric}{'  '}
                <button className="button_files" onClick={exportToPDF}><i className="fas fa-file-pdf"></i>Exportar PDF </button>{'  '}    {/* npm install jspdf xlsx */}
                <button className="button_files" onClick={exportToXLS}><i className="fas fa-file-excel"></i>Exportar XLS </button>{'  '}{/* npm install jspdf xlsx */}
                {/* <button onClick={closeModal}>Cerrar</button> */}
                <button className="close-button" onClick={closeModal} style={{
                  position: "absolute",
                  top: "10px", // Ajusta la posición vertical
                  right: "10px", // Ajusta la posición horizontal
                  background: "none",
                  border: "none",
                  fontSize: "1.5em",
                  cursor: "pointer",
                }}>X</button>
              </h4>
            </div>
            <div>
              {details.message ? (
                <p>{details.message}</p>
              ) : (
                Array.isArray(details) && details.length > 0 ? (
                  <div >
                    {details.map((detail, index) => (
                      <div key={index} className="detail-item">
                        <div key={detail.id_generar || index} className="card">
                          <div className="card-column data-column">

                            <h3 className="card-title">
                              <strong>{detail.hashCode}</strong>
                            </h3>
                            <h5>
                              <strong>Dirección:</strong> {detail.direccion} {detail.altura}{' '}{detail.piso && <><strong>Piso:</strong> {detail.piso}</>}{' '}
                              {detail.departamento && <><strong>Depto:</strong> {detail.departamento}</>}
                            </h5>
                            <h5>
                              <strong>Comuna:</strong> {detail.comuna} <strong>Barrio:</strong> {detail.barrio} <strong>CP:</strong> {detail.cp}
                            </h5>
                            <h5>
                              <strong>Contacto:</strong> {detail.nombreContacto} <strong>Tel:</strong> {detail.telefono}
                            </h5>
                            <h5>
                              <strong>Email:</strong> {detail.mailContacto}
                            </h5>
                            <h5>
                              <strong>Observaciones:</strong> {detail.observaciones}
                            </h5>
                            <h6>
                              <strong>Chofer: {detail.nombrechofer}</strong>
                            </h6>
                            <h5>
                              <strong>Fecha Venta:</strong> {detail.fechayhora} <strong> Estado:</strong> {detail.estado_descripcion}
                            </h5>
                            <h6>
                              <strong>ID Local:</strong> {detail.id_tienda} <strong>ID Logística:</strong> {detail.id_logistica}
                            </h6>
                          </div>
                          <div className="card-column qr-column">
                            <div className="buttonsCards">
                              <button className="button_files" onClick={() => exportCardPDF(detail)}><i className="fas fa-file-pdf"></i>Descargar</button>
                            </div>
                            <QRCodeReact value={detail.hashCode} size={128} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No se encontraron detalles disponibles.</p>
                )
              )}
            </div>

          </Modal>
        )}
        <br />
        <br />
      </div>

      <div className="logistica-rastreo" style={{ marginTop: '130px' }}>
        <p>Rastrear Envio</p>
        <div className="qr-input-container">
          <h3 className="qr-label">
            <img src={icon_QR} alt="Icono QR" className="qr-icon" />
            Buscar:</h3>
          <input
            type="text"
            id="qrRastreo"
            className="qr-input"
            placeholder="Ejemplo: XQR123456"
            value={qrInput}
            onChange={handleQrInputChange} // Manejar cambios en el input
          />
          <button onClick={handleSearch} className="search-button">
            <img src={icon_lupa} alt="Buscar" className="search-icon" /> Buscar
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}{" "}
        {/* Mensaje de error 
        {resultData && (*/}
        {/* <h3>Resultados de la Búsqueda:</h3> */}
        <br />
        {Array.isArray(resultData) && resultData.length > 0 ? (
          <div className="qr-label">
            <div className="card-list"> {/* Contenedor para las cards */}
              {resultData.map((item, index) => (

                <div key={item.id_generar || index} className="card">
                  <div className="card-column data-column">

                    <h3 className="card-title">
                      <strong>{item.hashCode}</strong>
                    </h3>
                    <h5>
                      <strong>Dirección:</strong> {item.direccion} {item.altura}{' '}{item.piso && <><strong>Piso:</strong> {item.piso}</>}{' '}
                      {item.departamento && <><strong>Depto:</strong> {item.departamento}</>}
                    </h5>
                    <h5>
                      <strong>Comuna:</strong> {item.comuna} <strong>Barrio:</strong> {item.barrio} <strong>CP:</strong> {item.cp}
                    </h5>
                    <h5>
                      <strong>Contacto:</strong> {item.nombreContacto} <strong>Tel:</strong> {item.telefono}
                    </h5>
                    <h5>
                      <strong>Email:</strong> {item.mailContacto}
                    </h5>
                    <h5>
                      <strong>Observaciones:</strong> {item.observaciones}
                    </h5>
                    <h5>
                      <strong>Fecha Venta:</strong> {item.fechayhora} <strong> Estado:</strong> {item.estado_descripcion}
                    </h5>
                    <h6>
                      <strong>Chofer: {item.nombrechofer}</strong>
                    </h6>
                    <h6>
                      <strong>ID Local:</strong> {item.id_tienda} <strong>ID Logística:</strong> {item.id_logistica}
                    </h6>
                  </div>
                  <div className="card-column qr-column">
                    <div className="buttonsCards">
                      <button className="button_files" onClick={() => exportCardPDF(item)}><i className="fas fa-file-pdf"></i>Descargar</button>
                    </div>
                    {/* //<QRCodeReact value={`{'id':'${item.hashCode}','sender_id':'${item.hashCtrl}'}`} size={128} />*/}
                    <QRCodeReact
                      value={
                        isNaN(item.hashCode)
                          ? item.hashCode // para XQR
                          : `{"id":"${item.hashCode}","sender_id":"${item.hashCtrl}"}` // para qr flex
                      }
                      size={150}
                    />

                  </div>
                </div>

              ))}

            </div>
          </div>
        ) : (
          <div className="logistica-busqueda"><p >No se encontraron resultados.</p>
          </div>
        )
        }
      </div>



      <div id='footer'>
        <div className='contenedor text-center'>

          <p><strong style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>XQR </strong> Tecnologia y soluciones en e-logistica. </p>

        </div>
      </div>
    </div>
  );
};

export default TiendaDashBoard;
