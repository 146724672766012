import emailjs from "emailjs-com";
import React, { useState } from "react";

// const initialState = {
//   name: "",
//   email: "",
//   message: "",
// };
export const Contact = (props) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    emailjs
      .send(
        "gmail_info_shipmaster",
        "template_shipmaster",
        formValues,
        "WXX8wn4yRQLjxVeyH"
      )
      .then(() => {
        setIsSent(true);
        resetForm();
        setFormValues({
          name: "",
          email: "",
          message: "",
          reply_To: "piloto.chevrolet@gmail.com",
        });
      })
      .catch((error) => console.log(error));
  };

  const resetForm = () => {
    setFormValues({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div id='contact' className='text-center'>
      <br /><br /><br />
      <div className='contactback'>
        <div className='section-title'>
          <h2>Contacto</h2>

        </div>

        <div className='text-center'>
          <div className='container'>
            <div className='col-md-8'>
              <div>
                <p style={{ fontFamily: 'Arial Black', textAlign: 'justify' }}>
                  ¿Listo para llevar tu logística de envíos a XQR?
                </p>
                <p style={{ fontFamily: 'Arial Black', textAlign: 'justify' }}>
                  ¡Contáctanos ahora mismo para comenzar! Estamos aquí para escucharte y responder a todas tus preguntas
                  y brindarte el apoyo que necesitas. ¡Esperamos escucharte pronto!.
                </p>
                <p style={{ fontFamily: 'Arial Black', textAlign: 'justify' }}>
                  Si no tenemos lo que necesitas, lo programamos! Somos propietarios del Codigo Fuente, lo que nos habilita a poder cumplir TODAS tus necesidades y requerimientos.
                </p>
              </div>
              <div className='row'>
                <form name='sentMessage' validate onSubmit={handleFormSubmit}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          id='name'
                          name='name'
                          className='form-control'
                          placeholder='Name'
                          required
                          onChange={handleChange}
                        />
                        <p className='help-block text-danger'></p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='email'
                          id='email'
                          name='email'
                          className='form-control'
                          placeholder='Email'
                          required
                          onChange={handleChange}
                        />
                        <p className='help-block text-danger'></p>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <textarea
                      name='message'
                      id='message'
                      className='form-control'
                      rows='4'
                      placeholder='Message'
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className='help-block text-danger'></p>
                  </div>
                  <div id='success'></div>
                  <button
                    type='submit'
                    className='btn btn-custom btn-lg'
                    style={{ backgroundColor: '#09C0FB', color: 'white' }}
                  >
                    Enviar
                  </button>
                  {isSent && <h2 style={{ color: 'black' }}>¡Mensaje enviado correctamente!</h2>}
                </form>
              </div>
            </div>
            <div className='col-md-3 col-md-offset-1 contact-info'>
              <div className='contact-item'>
                <h3>
                  <strong>Info de contacto</strong>
                </h3>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i> Direccion
                  </span>
                  {props.data ? props.data.address : 'Cargando'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Telefono
                  </span>{' '}
                  {props.data ? props.data.phone : 'Cargando'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> Email
                  </span>{' '}
                  {props.data ? props.data.email : 'Cargando'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='contenedor text-center'>
          <p>
          <p><strong style={{fontFamily: 'Arial, sans-serif', fontSize: '18px'}}>XQR </strong> Tecnologia y soluciones en e-logistica. </p>
           <strong>Director:</strong> Marcelo Salvagni 
          {'  '}<strong>Devoloper Full Stack:</strong> Ariel Aranda.{' '} 
            <a href='https://www.shipmaster.com.ar' rel='nofollow'>
              XQR
            </a>
          </p>
          
        </div>
      </div>
    </div>
  );
};
