import React, { useState } from "react";
import about1 from './Image/about/about1.jpg';
import about2 from './Image/about/about2.jpg';
import about3 from './Image/about/about3.jpg';
import about5 from './Image/about/about5.jpg';

export const About = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setShowModal(false);
  };

  return (
    <div id='about' className='aboutback'>
      <div className='section-title'>
        <h2 style={{ textAlign: 'center' }}>Acerca de XQR</h2>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-11 col-md-12'>
            <div className='about-text'>
              <p className='text-justificado'>
                {props.data && props.data.paragraph1 ? (
                  <div dangerouslySetInnerHTML={{ __html: props.data.paragraph1 }} />
                ) : (
                  'Cargando...'
                )}
              </p>

              <p />
            </div>
          </div>

        </div>
        <div>
          <div className='col-xs-12 col-md-12'>
            <div className='row'>
              {/* Primera fila (2 imágenes) */}
              <div className='col-xs-6 col-md-3'>
                <div>
                  <img
                    src={about1}
                    className='img-responsive'
                    alt=''
                    width='230px'
                    height='230px'
                    style={{ borderRadius: '8px 8px 8px 8px' }}
                    onClick={() => openModal('img/about/about1.jpg')}
                  />
                  {showModal && (
                    <div className='modal'>
                      <span className='close' onClick={closeModal}>
                        &times;
                      </span>
                      <img src={imageSrc} alt='' />
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div>
                  <img
                    src={about2}
                    className='img-responsive'
                    alt=''
                    width='230px'
                    height='230px'
                    style={{ borderRadius: '8px 8px 8px 8px' }}
                    onClick={() => openModal('img/about/about2.jpg')}
                  />
                  {showModal && (
                    <div className='modal'>
                      <span className='close' onClick={closeModal}>
                        &times;
                      </span>
                      <img src={imageSrc} alt='' />
                    </div>
                  )}
                </div>
              </div>

              {/* Segunda fila (2 imágenes) */}
              <div className='col-xs-6 col-md-3'>
                <div>
                  <img
                    src={about3}
                    className='img-responsive'
                    alt=''
                    width='230px'
                    height='230px'
                    style={{ borderRadius: '8px 8px 8px 8px' }}
                    onClick={() => openModal('img/about/about3.jpg')}
                  />
                  {showModal && (
                    <div className='modal'>
                      <span className='close' onClick={closeModal}>
                        &times;
                      </span>
                      <img src={imageSrc} alt='' />
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div>
                  <img
                    src={about5}
                    className='img-responsive'
                    alt=''
                    width='230px'
                    height='230px'
                    style={{ borderRadius: '8px 8px 8px 8px' }}
                    onClick={() => openModal('img/about/about5.jpg')}
                  />
                  {showModal && (
                    <div className='modal'>
                      <span className='close' onClick={closeModal}>
                        &times;
                      </span>
                      <img src={imageSrc} alt='' />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-xs-12 col-md-4'>
            <div className='row'>
              {/* Puedes agregar más contenido aquí si es necesario */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
