import React, { useEffect, useState } from "react";
import './administrarchoferes.css';

const AdministrarChoferes = () => {
    const [userId, setUserId] = useState("");
    const [Idlogis, setIdlogis] = useState("");
    const [Idtien, setIdtien] = useState("");

    const [drivers, setdrivers] = useState([]);
    const [showNewDriverForm, setShowNuevoDriver] = useState(false);
    const [NuevoDriver, setNuevoDriver] = useState({
        nombre: '',
        direccion: '',
        telefono: '',
        contacto: '',
        dni: '',
        vehiculo: '',
        patente: '',
        polizaseguro: '',
        imagen: '',
        user1: '',
        pass_user1: '',
        mail_user1: '',
        id_jerarquia: '',
        rol_user1: '',
        id_logistica: '',
        user_audit: '',
    });
    const [jerarquiaOptions, setJerarquiaOptions] = useState([]);
    const [rolOptions, setRolOptions] = useState([]);

    useEffect(() => {
        // Obtenemos el userId del localStorage
        setUserId(localStorage.getItem("userId"));
        console.log("userId en administrarchofere:" + userId)

        setIdlogis(localStorage.getItem("id_logis"));
        console.log("Idlogis:" + Idlogis)
        setIdtien(localStorage.getItem("id_tien"));
        console.log("Idtien:" + Idtien)

        // Realizar la solicitud GET al servidor PHP con el userId como parámetro
        fetch(`https://shipmaster.com.ar/backoffice/php/admindash/listarchoferesadmin.php`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de drivers con los datos recibidos
                setdrivers(data);
            })
            .catch(error => console.error("Error al obtener las drivers:", error));

        fetch('https://shipmaster.com.ar/backoffice/php/admindash/jerarquias.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de jerarquia');
                }
                return response.json();
            })
            .then(data => {
                // Actualiza el estado jerarquiaOptions con los datos obtenidos del servidor
                setJerarquiaOptions(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });


        fetch('https://shipmaster.com.ar/backoffice/php/admindash/rol.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de rol');
                }
                return response.json();
            })
            .then(data => {
                // Actualiza el estado jerarquiaOptions con los datos obtenidos del servidor
                setRolOptions(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [userId, Idlogis, Idtien]);

    const openNewDriverForm = () => {
        // Restablecer el estado NuevoDriver a un objeto vacío
        setNuevoDriver({
            nombre: '',
            direccion: '',
            telefono: '',
            contacto: '',
            dni: '',
            vehiculo: '',
            patente: '',
            polizaseguro: '',
            imagen: '',
            user1: '',
            pass_user1: '',
            mail_user1: '',
            id_jerarquia: '',
            rol_user1: '',
            id_logistica: Idlogis,
            user_audit: userId,
        });
        setShowNuevoDriver(true);
    };

    const handleSaveDriver = () => {
        console.log("Datos de nueva logistica y usuarios:", NuevoDriver); // Imprime los datos de editlogisticas por consola
        // Crear un objeto FormData para enviar los datos al servidor
        const formData = new FormData();
        // Iterar sobre cada campo de NuevoDriver
        Object.entries(NuevoDriver).forEach(([key, value]) => {
            // Verificar si el valor es un objeto de archivo (imagen)
            if (key === 'imagen') {
                // Si es un objeto de archivo (imagen), agregarlo al formData
                formData.append(key, value); // Aquí value debería ser el objeto File de la imagen
            } else {
                // Si no es un archivo, convertirlo a JSON y agregarlo
                //formData.append(key, JSON.stringify(value));
                const trimmedValue = typeof value === 'string' ? value.trim() : value;
                formData.append(key, trimmedValue);
            }
        });

        // Realiza una solicitud POST al servidor PHP para actualizar la logisticas
        fetch('https://shipmaster.com.ar/backoffice/php/savedriver.php', {
            method: 'POST',
            body: formData, // Enviar formData en lugar de JSON.stringify(NuevoDriver)
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cierra el formulario de edición
                    closeNLForm();
                    // Actualiza la lista de logisticass haciendo una nueva solicitud GET
                    //fetchlogisticass(); // Llama a la función fetchlogisticass para obtener las logisticass actualizadas
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al Agregar nueva logistica');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };


    const handleCancelNL = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        closeNLForm();
    };

    const closeNLForm = () => {
        setShowNuevoDriver(false);

    };



    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file); // Crear URL de objeto del archivo
        setNuevoDriver({ ...NuevoDriver, imagen: file, imageUrl: imageUrl }); // Agregar imageUrl al estado
    };

    return (
        <div className="containercursos">
            <h4>
                Administrar Usuarios de APP Logistica, Chofer  y Tienda{" "}
                <i
                    className="fas fa-graduation-cap"
                    style={{ paddingLeft: "20px", color: "#0c5da1" }}
                ></i>
            </h4>
            <h4>user id:{userId} - -id_logistica: {Idlogis} - id_Tienda: {Idtien}</h4>
            <button onClick={openNewDriverForm}>Nuevo Chofer</button>
            {/* Mostrar las drivers en una tabla */}

            <div className="drivers-container"> {/* Contenedor para los choferes */}
                {drivers.map(driver => (
                    <div key={driver.id_chofer} className="driver-info">
                        <div className="row">
                            <div>
                                <div className="profile-image">
                                    <img src={`https://www.shipmaster.com.ar/img/choferes/${driver.imagen}`} alt="Imagen Driver" />

                                </div>
                                <div className="picture-details"><strong>{driver.nombre}</strong></div>
                                <div className="info-description">
                                    <p><strong><span>{driver.descripcion}</span></strong> </p>
                                </div>
                            </div>
                            <div className="profile-details">
                                <div className="info-header">
                                    <div className="info-left"> 
                                        <p><strong>Usuario:</strong><span> {driver.usuario}{' '}</span><strong>Perfil:</strong>
                                            <span>
                                                {driver.jerarquia === '1' ? "Logística" : driver.jerarquia === '2' ? "Chofer" : driver.jerarquia === '3' ? "Tienda" : "Desconocido"}
                                            </span></p>
                                        <p><strong>Direccion:</strong><span>{driver.direccion}{'  '}</span>
                                            <strong>Contacto:</strong><span>{driver.contacto}</span>{'  '}<strong>DNI:</strong><span>{driver.dni}</span>{'  '}<strong>Telefono:</strong><span>{driver.telefono}</span></p>
                                        <p><strong>Vehiculo:</strong><span>{driver.vehiculo}{'  '}</span>
                                            <strong>Patente:</strong><span>{driver.patente}{'  '}</span>
                                            <strong>Seguro:</strong><span>{driver.seguro}{'  '}</span></p>
                                        <p><strong>Inicio:</strong> <span>{driver.inicio}{'  '}</span>
                                            <strong>Duración:</strong><span>{driver.dura}{'  '}</span>
                                            <strong>Alta:</strong><span>{driver.fechayhora}</span></p>

                                    </div>

                                </div>

                                <div className="info-buttons">
                                    <button className="edit"><i className="fas fa-edit"></i> Editar</button>
                                    <button className="delete"><i className="fas fa-trash-alt"></i> Eliminar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <br></br>
            {/* <table>
                <thead>
                    <tr>
                        {/ *`chofer`(`id_chofer`, `id_logistica`, `usuario`, `password`, `nombre`, `descripcion`, `imagen`, `jerarquia`, `inicio`, `dura`, `link`, `fechayhora`) * /}
                        <th>id_chofer</th>
                        <th>id_logistica</th>
                        <th>Usuario</th>
                        <th>Password</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Imagen</th>
                        <th>Jerarquia</th>
                        <th>Inicio</th>
                        <th>Dura</th>
                        <th>Link</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {drivers.map(driver => (
                        <tr key={driver.id_chofer}>
                            <td>{driver.id_chofer}</td>
                            <td>{driver.id_logistica}</td>
                            <td>{driver.usuario}</td>
                            <td>{driver.password}</td>
                            <td>{driver.nombre}</td>
                            <td>{driver.descripcion}</td>
                            <td> {/ *<img src={driver.imagen} alt="Imagen Driver" style={{ maxWidth: "100px" }} /> * /}
                                <img src={`https://www.shipmaster.com.ar/img/choferes/${driver.imagen}`} alt="Imagen Driver" style={{ maxWidth: "100px" }} />
                            </td>
                            <td>{driver.jerarquia}</td>
                            <td>{driver.inicio}</td>
                            <td>{driver.dura}</td>
                            <td>{driver.link}</td>
                            <td>{driver.fechayhora}</td>
                            { * Agrega más campos según tus necesidades * /}
                        </tr>
                    ))}
                </tbody>
            </table> */}
            {/* Mostrar el formulario de ALTA de logistica si showNewLogisticaForm es true */}
            {showNewDriverForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Nuevo Chofer</h3>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '50%', textAlign: "center" }}>Datos del Chofer</th>
                                    <th colSpan="2" style={{ width: '50%', textAlign: "center" }}>Usuario Sistema</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td>



                                        {/*`chofer`( `usuario`, `password`, `nombre`, `descripcion`, `imagen`, `jerarquia`, `inicio`, `dura`, `link`, `fechayhora`) */}


                                        <form>

                                            <label htmlFor="nombre">Nombre y Apellido:</label>
                                            <input
                                                type="text"
                                                id="nombre"
                                                name="nombre"
                                                value={NuevoDriver.nombre}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, nombre: e.target.value })}
                                            />

                                            <label htmlFor="dni">DNI:</label>
                                            <input
                                                type="text"
                                                id="dni"
                                                name="dni"
                                                value={NuevoDriver.dni}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, dni: e.target.value })}
                                            />

                                            <label htmlFor="direccion">Direccion:</label>
                                            <input
                                                type="text"
                                                id="direccion"
                                                name="direccion"
                                                value={NuevoDriver.direccion}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, direccion: e.target.value })}
                                            />

                                            <label htmlFor="telefono">Telefono:</label>
                                            <input
                                                type="text"
                                                id="telefono"
                                                name="telefono"
                                                value={NuevoDriver.telefono}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, telefono: e.target.value })}
                                            />

                                            <label htmlFor="contacto">Contacto de emergencia:</label>
                                            <input
                                                type="text"
                                                id="contacto"
                                                name="contacto"
                                                value={NuevoDriver.contacto}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, contacto: e.target.value })}
                                            />

                                            <label htmlFor="vehiculo">Vehiculo:</label>
                                            <input
                                                type="text"
                                                id="vehiculo"
                                                name="vehiculo"
                                                value={NuevoDriver.vehiculo}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, vehiculo: e.target.value })}
                                            />

                                            <label htmlFor="patente">Patente:</label>
                                            <input
                                                type="text"
                                                id="patente"
                                                name="patente"
                                                value={NuevoDriver.patente}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, patente: e.target.value })}
                                            />

                                            <label htmlFor="polizaseguro">Poliza del Seguro:</label>
                                            <input
                                                type="text"
                                                id="polizaseguro"
                                                name="polizaseguro"
                                                value={NuevoDriver.polizaseguro}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, polizaseguro: e.target.value })}
                                            />

                                        </form>

                                    </td>
                                    <td>
                                        <form>
                                            <h5 >Usuario APP XQR:</h5>
                                            <label htmlFor="Jerarquia">Jerarquia:</label>
                                            <select
                                                id="id_jerarquia"
                                                name="id_jerarquia"
                                                value={NuevoDriver.id_jerarquia}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, id_jerarquia: e.target.value })}
                                            >
                                                {jerarquiaOptions.map(option => (
                                                    <option key={option.id_jerarquia} value={option.id_jerarquia}>{option.id_jerarquia}-{option.nombre_jerarquia}</option>
                                                ))}
                                            </select>


                                            <p></p>

                                            <label htmlFor="rol_user1">rol:</label>
                                            <select
                                                id="rol_user1"
                                                name="rol_user1"
                                                value={NuevoDriver.rol_user1}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, rol_user1: e.target.value })}
                                            >
                                                {rolOptions.map(option => (
                                                    <option key={option.id_rol} value={option.id_rol}>{option.nombre_rol}</option>
                                                ))}
                                            </select>
                                            <br /><br />
                                            <label htmlFor="user1">Usuario:</label>
                                            <input
                                                type="text"
                                                id="user1"
                                                name="user1"
                                                value={NuevoDriver.user1}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, user1: e.target.value })}
                                            />
                                            <label htmlFor="user1">Pass:</label>
                                            <input
                                                type="text"
                                                id="pass_user1"
                                                name="pass_user1"
                                                value={NuevoDriver.pass_user1}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, pass_user1: e.target.value })}
                                            />
                                            <br />

                                            <label htmlFor="mail_user1">Mail Usuario:</label>
                                            <input
                                                type="text"
                                                id="mail_user1"
                                                name="mail_user1"
                                                value={NuevoDriver.mail_user1}
                                                onChange={(e) => setNuevoDriver({ ...NuevoDriver, mail_user1: e.target.value })}
                                            />





                                            <br /><br />
                                            <label htmlFor="imagen">Imagen:</label>
                                            <input
                                                type="file"
                                                id="imagen"
                                                name="imagen"
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e)}
                                            />

                                            {NuevoDriver.imageUrl && (
                                                <div>
                                                    <p>Vista previa:</p>
                                                    <img
                                                        src={NuevoDriver.imageUrl}
                                                        alt="Imagen Chofer"
                                                        style={{ width: '180px', height: '150px' }}
                                                    />
                                                </div>
                                            )}

                                            <br />

                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <button onClick={handleSaveDriver}>Guardar</button>
                        <button onClick={handleCancelNL}>Cancelar</button>
                        <p style={{ marginBottom: '30px' }} />
                    </div>
                </div>
            )}
            <div className="footer">
                <p>shipmaster - Tecnología en shipmaster</p>
            </div>
        </div>
    );
};

export default AdministrarChoferes;
