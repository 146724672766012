import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/navigation";
import Home from "./pages/Home";
import Blog from "./components/dashboards/blog";
import BlogPost from "./components/dashboards/BlogPost";
import AdmIn from "./pages/Admin";
import CrudPost from "./components/admin/CrudPost";
//import Usuarios from "./components/usuarios/usuarios";
import Descripcion from "./components/usuarios2/descripcion";
import Usuarios2 from "./components/usuarios2/usuarios2";
import UserDash from "./components/usuarios2/userDash";
import AdminDash from "./components/usuarios2/adminDash";
import FlexScreen from "./components/dashboards/FlexScreen";
// import Campus from "./components/campus/Campus";
// import Inscripcion from "./components/campus/Inscripcion";
// import Alumno from "./components/campus/inside/Alumno";
// import Login from "./components/campus/Login";
import AdminDashboard from "./components/campus/administracion/AdminDashboard";
//import Post from "./components/containers/Post";
//import BlogIA from "./components/containers/BlogIA";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import Identidad from "./components/usuarios2/Identidad";
import PortalTienda from "./components/usuarios2/PortalTienda";
import PortalCliente from "./components/usuarios2/PortalCliente";
import PortalLogistica from "./components/usuarios2/PortalLogistica";
import PortalRider from "./components/usuarios2/PortalRider";
import PortalAdmin from "./components/usuarios2/PortalAdmin";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {


  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/blog/*" exact element={<Posts />} /> */}
        <Route path="/Admin" exact element={<AdmIn />} />
        <Route path="/AdminDashboard" exact element={<AdminDashboard />} />
        <Route path="/CrudPost" exact element={<CrudPost />} />
        {/* <Route path="/Usuarios" exact element={<Usuarios />} /> */}
        <Route path="/Descripcion" exact element={<Descripcion />} />
        <Route path="/Usuarios" exact element={<Usuarios2 />} />
        <Route path="/AdminDash" exact element={<AdminDash />} />
        <Route path="/FlexScreen" element={<FlexScreen />} />
        <Route path="/PortalAdmin" exact element={<PortalAdmin />} />
        <Route path="/Identidad" exact element={<Identidad />} />
        <Route path="/UserDash" exact element={<UserDash />} />
        <Route path="/PortalTienda" exact element={<PortalTienda />} />
        <Route path="/PortalCliente" exact element={<PortalCliente />} />
        <Route path="/PortalLogistica" exact element={<PortalLogistica />} />
        <Route path="/PortalRider" exact element={<PortalRider />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} />

        {/*<Route path="/Campus" exact element={<Campus />} />
        <Route path="/Login/*" exact element={<Login />}/>
        <Route path="/Alumno" exact element={<Alumno />}/>
        <Route path="/Inscripcion" exact element={<Inscripcion />}/>
         <Route path="/Blog/*" exact element={<BlogIA />} /> */}
        {/* <Route path='/Blog/:id' exact strict element={<Post />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
