// setIdlogistica(localStorage.getItem("id_logis"));
// console.log("Idlogis:" + Idlogistica)
// setIdtien(localStorage.getItem("id_tien"));
// console.log("Idtien:" + Idtien)
import React, { useEffect, useState } from "react";
import './administrarchoferes.css';
import jsPDF from 'jspdf'; // Importa jsPDF primero
import 'jspdf-autotable'; // Luego, importa jspdf-autotable
import * as XLSX from 'xlsx';

const LogisticaConsolidados = () => {
    const [userId, setUserId] = useState("");
    const [Idlogis, setIdlogis] = useState("");
    const [startDate, setStartDate] = useState(getInitialStartDate());
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
    const [driversData, setDriversData] = useState([]);

    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
        setIdlogis(localStorage.getItem("id_logis"));
        fetchData();
    }, [Idlogis, startDate, endDate]);

    function getInitialStartDate() {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth();
        const year = today.getFullYear();
        return new Date(year, month, day <= 15 ? 1 : 16).toISOString().slice(0, 10);
    }

    const fetchData = async () => {
        try {
            const response = await fetch('https://shipmaster.com.ar/backoffice/php/web/getConsolidadoData.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Idlogis: Idlogis,
                    startDate: startDate,
                    endDate: endDate,
                }),
            });
            const data = await response.json();
            setDriversData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['ID Chofer', 'Nombre', 'Beneficios', 'Colectado', 'Entregado', 'Fallos', 'Cambio', 'Tienda']],
            body: driversData.map(driver => [
                driver.id_chofer,
                driver.nombre,
                driver.beneficios,
                driver.estado2,
                driver.estado6,
                driver.estado11,
                driver.estado7,
                driver.estado8,
                driver.estado9,
                driver.estado12,
                driver.estado11,
                driver.estado16,
            ]),
        });
        doc.save('drivers_data.pdf');
    };

    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const wsData = [['ID Chofer', 'Nombre', 'Beneficios', 'Colectado', 'Entregado', 'Fallos', 'Cambio', 'Tienda'],
        ...driversData.map(driver => [
            driver.id_chofer,
            driver.nombre,
            driver.beneficios,
            driver.estado2,
            driver.estado6,
            driver.estado11,
            driver.estado7,
            driver.estado8,
            driver.estado9,
            driver.estado12,
            driver.estado11,
            driver.estado16,
        ])];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, "Drivers Data");
        XLSX.writeFile(wb, "Consolidados.xlsx");
    };
    const handleSearch = () => {
        fetchData();
    };

    return (
        <div className="containercursos">
            <h4>Logistica Consolidados</h4>
            <h4>user id:{userId} - id_logistica: {Idlogis}</h4>
            <div>
                <label>Fecha de inicio:</label>
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <label>Fecha de fin:</label>
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                <button onClick={handleSearch}>Aceptar</button>
            </div>
            <div className="drivers-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID Chofer</th>
                            <th>Nombre</th>
                            <th>Beneficios</th>
                            <th>Colectado</th>
                            <th>Entregado</th>
                            <th>Fallos</th>
                            <th>Cambio</th>
                            <th>Tienda</th>
                        </tr>
                    </thead>
                    <tbody>
                        {driversData.map(driver => (
                            <tr key={driver.id_chofer}>
                                <td>{driver.id_chofer}</td>
                                <td>{driver.nombre}</td>
                                <td>{driver.beneficios}</td>
                                <td>{driver.estado2}</td>
                                <td>{driver.estado6}</td>
                                <td>{parseInt(driver.estado7 || 0) + parseInt(driver.estado8 || 0) + parseInt(driver.estado9 || 0) + parseInt(driver.estado12 || 0)}</td>
                                <td>{parseInt(driver.estado11 || 0)}</td>
                                <td>{parseInt(driver.estado16 || 0)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br />
            <div>
                <button onClick={exportToPDF}>Exportar a PDF</button>
                <button onClick={exportToExcel}>Exportar a Excel</button>
            </div>
            <div className="footer">
                <p>XQR - Tecnologia y soluciones en e-logistica.</p>
            </div>
        </div>
    );
};

export default LogisticaConsolidados;